import axios from "axios";
import { goApiRoot } from "@/preferences";

export function authorize(payload) {
  return axios.post(`${goApiRoot}/v2/oauth/authorize`, payload);
}

export function list() {
  return axios.get(`${goApiRoot}/v2/oauth/registered`);
}

export function del(instanceId) {
  return axios.delete(`${goApiRoot}/v2/oauth/registered/${instanceId}`);
}
