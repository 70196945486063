<script>
import { mapGetters } from "vuex";
export default {
  name: "KeepAuth",
  props: {
    intervalMS: {
      type: Number,
      default: 30000
    }
  },
  data() {
    return {
      // If user will perform any of the actions in the `isUserAliveEventTriggers` this will be true
      isUserAlive: true,
      isUserAliveEventTriggers: ["mousemove"]
    };
  },
  computed: {
    ...mapGetters(["isAutoLogoutWarningModalVisible", "isLoggedIn"])
  },
  watch: {
    isUserAlive(isAlive) {
      // Token will be refreshed only if the user triggered any of the events in `isUserAliveEventTriggers`.
      // Besides the user should be logged in and the `AutoLogoutWarningModal` should not be visible
      if (isAlive && this.isLoggedIn && !this.isAutoLogoutWarningModalVisible) {
        this.$store.dispatch("refreshToken");
      }
    }
  },
  created() {
    // Setup the event handlers
    this.isUserAliveEventTriggers.forEach(evt => window.addEventListener(evt, this.setIsUserAlive));

    // Setup the clock to check for user activity every `intervalMS`
    this.startIntervalCheckIsUserAlive();
  },
  methods: {
    startIntervalCheckIsUserAlive() {
      // Starts the clock to check for user activity every `intervalMS`
      window.setInterval(() => {
        this.isUserAlive = false;
      }, this.intervalMS);
    },
    setIsUserAlive() {
      // Event handler
      if (!this.isUserAlive) {
        this.isUserAlive = true;
      }
    }
  },
  render() {
    return null;
  }
};
</script>
