<template>
  <div class="d-flex flex-column">
    <b-button
      variant="light ghost btn-square-icon power-button"
      @click="logout">
      <b-icon-power />
    </b-button>
    <div id="automatic-logout">
      <div>{{ autoLogoutMinutes }} {{ $t("MIN") }}</div>
    </div>
    <b-popover
      target="automatic-logout"
      triggers="hover"
      placement="left">
      {{ $t("auto_logout_after_min").replace("$min", autoLogoutMinutes) }}
    </b-popover>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LogoutButton",
  computed: {
    ...mapGetters(["autoLogoutMinutes"])
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>
