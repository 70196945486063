import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
  kpiAppSettingsKey,
  ignoreSettingsSaveKey,
  execWithCooldown
} from "./util";
import {
  getKpis,
  getKpiYears,
  getKpiHistory,
  getKpiAggregates,
  addKpiFavorite,
  deleteKpiFavorite,
  getKpiMeta,
  putKpiPlanningValues,
  createFavoritesKpis,
  getAllCurrencies,
  createCurrency,
  updateCurrencyCompany,
  editCurrency,
  getCurrency,
  setCurrency,
  getLastForecasts,
  postNewForecast,
  addCustomKpi,
  updateCustomCategories,
  updateCustomKpi,
  deleteCustomKpi,
  getKpiCategories,
  addCustomKpiCategory,
  saveCustomDashboardPositions,
  deleteCustomKpiCategory,
  deleteCustomDashboardById,
  updateCustomDashboard,
  getCustomDashboard,
  createCustomDashboard,
  editCustomPosition,
  addKpiToCustomDashboard,
  removeKpiFromCustomDashboard,
  addMultipleKpisToCustomDashboard,
  updateCustomDashboardById,
  updateCustomDashboardPermissions,
  updateKpiMonthlyValues,
  getFinancialKpiCategories,
  updateKpiRecommendationsMetaData,
  getKpiDimensions
} from "@/services/kpiService";
import { getForecastableSources } from "@/services/forecastingService.js";
import { getSharedKpis } from "@/services/investorReportingService.js";
import { updateUserAppSettings } from "@/services/settingsService.js";
import Vue from "vue";
import axios from "axios";

const defaultFilterSettings = {
  applyBenchmarkFilter: false,
  benchmarkFilterUp: true,
  benchmarkFilterNeutral: true,
  benchmarkFilterDown: true,
  benchmarkFilterNull: true,
  trendFilterUp: true,
  trendFilterNeutral: true,
  trendFilterDown: true,
  trendFilterNull: true,
  planningAsBenchmark: false,
  trendDuration: 1,
  trendOffset: 0,
  benchmarkCategory: "ValueWorks",
  searchQuery: ""
};

const initialState = {
  kpiMetaState: stateInitial,
  kpiMeta: [],

  operational: {
    kpis: [],
    state: stateInitial
  },
  strategic: {
    kpis: [],
    state: stateInitial
  },

  financial: {
    kpis: [],
    state: stateInitial
  },

  custom: {
    kpis: [],
    state: stateInitial
  },

  favorite: {
    kpis: [],
    state: stateInitial
  },

  treeview: {
    kpis: [],
    state: stateInitial
  },

  highLowPerforming: {
    kpis: { high: [], low: [] },
    state: stateInitial
  },

  singleKpi: {
    kpi: undefined,
    kpi2: undefined,
    state: stateInitial
  },

  kpiList: {
    kpis: [],
    state: stateInitial
  },

  // NOTE: To keep the state for the Detail page, we have two different means of loading history
  // This is used by the detail page, while kpiPlanningHistories is used by the small previews in the Kpi Cards
  kpiHistoryState: stateInitial,
  kpiHistory: [],
  kpiHistoryUpdates: [],
  kpiHistoryUpdatesState: stateInitial,
  reportedYears: [new Date().getFullYear()],
  plannedYears: [new Date().getFullYear()],
  currency: { code: "EUR", exchangeRate: 1.0 },
  currencyState: stateInitial,
  currencies: [],
  currenciesState: stateInitial,
  kpiCategories: [],
  kpiFinancialCategories: [],
  customDashboards: [],
  kpiPlanningHistory: [],
  kpiPlanningHistoryQuery: null,
  kpiPlanningHistoryState: stateInitial,
  kpiPlanningHistoryYear: 0,
  customDashboardsLoadingState: stateInitial,
  // NOTE: this is used by the small previews in the KPI cards, to keep state of the loaded year, the detail screen uses kpiHistory
  kpiHistoryPreviews: [],

  kpiAggregateState: stateInitial,
  kpiAggregates: [],

  // NOTE: this is used when updating the reporting OR planning KPI monthly values
  kpiUpdatedMonthlyValues: [],

  kpiForecasts: [],
  kpiForecastsBasicScopes: [],
  kpiNewForecastBasicScope: {},
  kpiForecastsLoadingState: stateInitial,

  filterSettings: defaultFilterSettings,

  operationalDashboardViewPosition: {
    divElement: undefined,
    xPosition: undefined,
    yPosition: undefined
  },

  selectedKpiForComparison: null,
  sharedKpiIds: [],
  shouldMergeDynamicKpiSection: false,

  kpiDimensions: [],
  forecastableSourcesForCategories: {},
  listviewFilters: {}
};
function getKpisToUpdate(state, selectedKpi) {
  if (selectedKpi.type == "strategic") {
    return state.strategic.kpis;
  } else if (selectedKpi.type == "custom") {
    return state.custom.kpis;
  } else {
    return state.operational.kpis;
  }
}

function getKpiWithId(kpisToUpdate, id) {
  for (let i in kpisToUpdate) {
    if (kpisToUpdate[i].id == id) {
      return kpisToUpdate[i];
    }
  }
}

function geKpitCustomDashboardIndex(kpi, dashboardId) {
  for (let i in kpi.customDashboardsKpi) {
    if (kpi.customDashboardsKpi[i].dashboard == dashboardId) {
      return i;
    }
  }

  return 0;
}

function getKpiWithPosition(kpisToUpdate, position) {
  for (let k in kpisToUpdate) {
    if (kpisToUpdate[k].customerSpecificPosition === position) {
      return kpisToUpdate[k];
    }
  }
}

function swapKpisPositions(kpi1, kpi2) {
  let aux = kpi1.customerSpecificPosition;
  kpi1.customerSpecificPosition = kpi2.customerSpecificPosition;
  kpi2.customerSpecificPosition = aux;
}

const mutations = {
  setListviewFilters(state, filters) {
    state.listviewFilters = filters;
  },
  setOperationalDashboardViewElement(state, divElement) {
    state.operationalDashboardViewPosition.divElement = divElement;
  },
  setOperationalDashboardViewPosition(state, position) {
    state.operationalDashboardViewPosition.xPosition = position.xPosition;
    state.operationalDashboardViewPosition.yPosition = position.yPosition;
  },
  editKpisCustomPosition(state, selectedKpi) {
    let kpisToUpdate = getKpisToUpdate(state, selectedKpi);

    for (let i in selectedKpi.positionsToMove) {
      let positionToMove = selectedKpi.positionsToMove[i];

      let kpi1 = getKpiWithId(kpisToUpdate, positionToMove.secondId);

      let kpi2 = getKpiWithPosition(kpisToUpdate, positionToMove.customerSpecificPosition);
      swapKpisPositions(kpi1, kpi2);
    }
  },

  swapKpisPositions(state, positionsToMove) {
    let kpisToUpdate = getKpisToUpdate(state, positionsToMove);

    if (positionsToMove.type == "custom") {
      let firstKpi = kpisToUpdate[positionsToMove.movingIndex];
      firstKpi = getKpiWithId(state.kpiMeta, firstKpi.id);
      let firstKpiDashboardPos = geKpitCustomDashboardIndex(firstKpi, positionsToMove.dashboardId);

      let secondKpi = kpisToUpdate[positionsToMove.futureIndex];
      secondKpi = getKpiWithId(state.kpiMeta, secondKpi.id);
      let secondKpiDashboardPos = geKpitCustomDashboardIndex(
        secondKpi,
        positionsToMove.dashboardId
      );

      let aux = firstKpi.customDashboardsKpi[firstKpiDashboardPos].position;
      firstKpi.customDashboardsKpi[firstKpiDashboardPos].position =
        secondKpi.customDashboardsKpi[secondKpiDashboardPos].position;
      secondKpi.customDashboardsKpi[secondKpiDashboardPos].position = aux;
    } else {
      kpisToUpdate[positionsToMove.movingIndex] = kpisToUpdate.splice(
        positionsToMove.futureIndex,
        1,
        kpisToUpdate[positionsToMove.movingIndex]
      )[0];
    }
  },

  async setFilterSettings(state, settings) {
    const shouldSaveAppSettings = !(ignoreSettingsSaveKey in settings);
    delete settings[ignoreSettingsSaveKey];

    state.filterSettings = { ...state.filterSettings, ...settings };

    if (shouldSaveAppSettings) {
      execWithCooldown(async () => {
        await updateUserAppSettings(kpiAppSettingsKey, { settings: state.filterSettings });
      });
    }
  },

  resetFilterSettings(state) {
    /* Reset everything but time duration and offset, to persist accurate trend calculation.
    From the functional perspective duration and offset are not used to *filter* items,
    but just to decide about what values should be displayed on already *filtered* items. */

    state.filterSettings = {
      ...defaultFilterSettings,
      trendDuration: state.filterSettings.trendDuration,
      trendOffset: state.filterSettings.trendOffset
    };
  },

  createCurrency(state, currencies) {
    state.currencies.push(currencies);
  },

  // search in kpiUpdatedMonthlyValues a kpi which has the same id like the one we're trying to add now
  // and has the same date as well
  addKpiUpdatedMonthlyValue(state, monthlyValue) {
    let existingKpi = state.kpiUpdatedMonthlyValues.find(
      item => item.kpi === monthlyValue.kpi && item.date == monthlyValue.date
    );
    // if we did not find such a kpi then
    // we can say that there is not already a kpi of ours in this array so we add it
    if (!existingKpi) {
      state.kpiUpdatedMonthlyValues.push(monthlyValue);
    } else {
      // else if it has been found then we update only the value of that kpi
      // to avoid adding it multiple times this kpi in our array
      existingKpi.value = monthlyValue.value;
    }
  },

  // remove the kpi data from our kpiUpdatedMonthlyValues array
  // based on the kpiId and date pair
  removeKpiUpdatedMonthlyValue(state, { kpiId, date }) {
    let kpiIndex = state.kpiUpdatedMonthlyValues.findIndex(
      item => item.kpi === kpiId && item.date === date
    );

    if (kpiIndex >= 0) {
      state.kpiUpdatedMonthlyValues.splice(kpiIndex, 1);
    }
  },

  clearKpiUpdatedMonthlyValues(state) {
    state.kpiUpdatedMonthlyValues = [];
  },

  currencyLoading(state) {
    state.currencyState = stateLoading;
  },

  updateCurrencyCompany(state, selectedCurrency) {
    for (let i in state.currencies) {
      if (state.currencies[i].code != selectedCurrency.selectedCurrency) {
        state.currencies[i].corporate = false;
      } else {
        state.currencies[i].corporate = true;
      }
    }
  },

  editCurrency(state, exchangeRate) {
    for (let i in state.currencies) {
      if (state.currencies[i].code == exchangeRate.code) {
        state.currencies[i].exchangeRate = exchangeRate;
      }
    }
  },

  kpisLoading(state, query) {
    if (query.type !== undefined) {
      state[query.type].state = stateLoading;
    }
  },
  kpisLoaded(state, payload) {
    if (payload.type !== undefined) {
      state[payload.type].state = stateLoaded;
      state[payload.type].kpis = payload.kpis;
    }
  },
  kpisError(state, payload) {
    if (payload.type !== undefined) {
      state[payload.type].state = stateError;
      state[payload.type].state.errorMessage = payload.error;
      state[payload.type].kpis = [];
    }
  },
  kpiFavoriteUpdating(state) {
    Vue.set(state, "kpiMetaLoadingState", stateLoading);
  },
  removeKpiFromCustomDashboard(state, payloadData) {
    let item = state.kpiMeta.find(item => item.id === payloadData.kpi);
    let indexDashboard = item.customDashboards.indexOf(payloadData.dashboard);
    item.customDashboards.splice(indexDashboard, 1);
  },
  kpiFavoriteUpdated(state, payload) {
    const item = state.kpiMeta.find(item => item.id === payload.kpiId);
    if (item) {
      item.favorite = payload.favorite;
    }
    if (!payload.favorite) {
      // Favorite was removed, check if an item exists in favorite data and if yes remove the item
      const favoriteIndex = state.favorite.kpis.findIndex(item => item.id === payload.kpiId);
      if (favoriteIndex > -1) {
        state.favorite.kpis.splice(favoriteIndex, 1);
      }
    }
    Vue.set(state, "kpiMetaLoadingState", stateLoaded);
  },
  kpisUpdateError(state, error) {
    Vue.set(state, "kpiMetaLoadingState", stateError);
    state.kpiMetaErrorState.errorMessage = error;
  },

  kpiLoading(state) {
    state.singleKpi.state = stateLoading;
  },
  kpiLoaded(state, payload) {
    if (payload.storeSingleKpi == 2) {
      state.singleKpi.kpi2 = payload.kpi;
    } else {
      state.singleKpi.kpi = payload.kpi;
    }
    state.singleKpi.state = stateLoaded;
  },
  kpiError(state, payload) {
    state.singleKpi.state.error = payload.error;
    state.singleKpi.state = stateError;
  },

  // Kpi Meta Data
  // NOTE: we have to user Vue.set here since we use the loading state in the getter to attach meta data
  // This will lead to vue adding a listener to the object itself, if we override the object that listener will be overriden
  // and reactivity will be lost.
  kpiMetaLoading(state) {
    Vue.set(state, "kpiMetaLoadingState", stateLoading);
  },

  customDashboardLoading(state) {
    state.customDashboardsLoadingState = stateLoading;
  },
  customDashboardReset(state) {
    state.customDashboardsLoadingState = stateInitial;
  },
  kpiMetaLoaded(state, kpis) {
    state.kpiMeta = kpis;
    Vue.set(state, "kpiMetaLoadingState", stateLoaded);
  },
  kpiMetaError(state, error) {
    Vue.set(state, "kpiMetaLoadingState", stateError);
    state.kpiMetaLoadingState.errorMessage = error;
  },

  createCurrencyError(state, message) {
    state.currenciesState = stateError;
    state.currenciesState.errorMessage = message;
  },

  updateCurrencyCompanyError(state, message) {
    state.currenciesState = stateError;
    state.currenciesState.errorMessage = message;
  },

  editCurrencyError(state, message) {
    state.currenciesState = stateError;
    state.currenciesState.errorMessage = message;
  },

  kpiYearsLoaded(state, years) {
    const thisYear = new Date().getFullYear();

    state.reportedYears = years.reportingYearsAvailable;
    if (!state.reportedYears.includes(thisYear)) {
      state.reportedYears.push(thisYear);
    }
    state.reportedYears.sort();

    state.plannedYears = years.planningYearsAvailable;
    if (!state.plannedYears.includes(thisYear)) {
      state.plannedYears.push(thisYear);
    }
    state.plannedYears.sort();
  },

  kpiHistoryLoading(state, query) {
    state.kpiHistoryQuery = query;
    state.kpiHistoryState = stateLoading;
  },
  kpiHistoryUpdatedLoading(state) {
    state.kpiHistoryUpdatesState = stateLoading;
  },
  kpiHistoryLoaded(state, { values, query }) {
    if (query.shouldUpdateValues) {
      state.kpiHistoryUpdates = values;
    } else {
      state.kpiHistory = values;
      state.kpiHistoryQuery = query;
    }
    state.kpiHistoryState = stateLoaded;
  },
  kpiHistoryUpdatesLoaded(state) {
    state.kpiHistoryUpdatesState = stateLoaded;
  },
  kpisHistoryError(state, error) {
    state.kpiHistoryState = stateError;
    state.kpiHistoryState.errorMessage = error.messsage;
  },
  kpisHistoryUpdatesError(state, error) {
    state.kpiHistoryUpdatesState = stateError;
    state.kpiHistoryUpdatesState.errorMessage = error.message;
  },
  kpiPlanningHistoryLoading(state, query) {
    state.kpiPlanningHistoryState = stateLoading;
    state.kpiPlanningHistoryQuery = query;
  },
  kpiPlanningHistoryLoaded(state, { values, query }) {
    state.kpiPlanningHistory = values.results;
    state.kpiPlanningHistoryQuery = query;
    state.kpiPlanningHistoryState = stateLoaded;
  },
  kpiPlanningHistoryError(state, error) {
    state.kpiPlanningHistoryState = stateError;
    state.kpiPlanningHistoryError = error;
  },
  kpiAggregatesLoading(state, query) {
    state.kpiAggregateState = stateLoading;
    state.timeRange = query.timeRange;
    state.endDate = query.endDate;
  },
  kpiAggregatesLoaded(state, aggregates) {
    state.kpiAggregates = aggregates;
    state.kpiAggregateState = stateLoaded;
  },
  kpiAggregatesError(state, error) {
    state.kpiAggregateState = stateError;
    state.kpiAggregateState.errorMessage = error.message;
  },
  kpiHistoryPreviewLoading(state, payload) {
    const previews = state.kpiHistoryPreviews;
    const item = previews.find(obj => obj.id === payload.kpi.id);
    if (item) {
      item.state = stateLoading;
      item.data = [];
    } else {
      previews.push({
        id: payload.kpi.id,
        state: stateLoading,
        data: []
      });
    }
  },
  kpiHistoryPreviewLoaded(state, payload) {
    const previews = state.kpiHistoryPreviews;
    const item = previews.find(obj => obj.id === payload.kpi.id);
    if (item) {
      item.state = stateLoaded;
      item.data = payload.data;
      item.endDate = payload.endDate;
      item.timeRange = payload.timeRange;
    } else {
      previews.push({
        id: payload.kpi.id,
        state: stateLoaded,
        endDate: payload.endDate,
        timeRange: payload.timeRange,
        data: payload.data
      });
    }
  },
  kpiHistoryPreviewError(state, payload) {
    const previews = state.kpiHistoryPreviews;
    const item = previews.find(obj => obj.id === payload.kpi.id);
    if (item) {
      item.state = { ...stateError, error: payload.error };
      item.error = payload.error;
    } else {
      previews.push({
        id: payload.kpi.id,
        state: { ...stateError, error: payload.error }
      });
    }
  },
  loadCurrencyError(state, error) {
    state.currencyState = stateError;
    state.currencyState.errorMessage = error.message;
  },
  currencyLoaded(state, currency) {
    state.currency = currency;
  },
  loadCurrenciesError(state, error) {
    state.currenciesState = stateError;
    state.currenciesState.errorMessage = error.message;
  },
  currenciesLoading(state) {
    state.currenciesState = stateLoading;
  },
  currenciesLoaded(state, currencies) {
    state.currencies = currencies;
    state.currenciesState = stateLoaded;
  },
  favoriteKpisUpdate(state, kpis) {
    state.favorite.kpis = JSON.parse(JSON.stringify(kpis));
  },
  customDashboardsUpdate(state, custom) {
    state.customDashboards = JSON.parse(JSON.stringify(custom));
  },
  kpiForecastsLoading(state) {
    state.kpiForecastsLoadingState = stateLoading;
  },
  kpiForecastsLoaded(state, forecasts) {
    state.kpiForecastsLoadingState = stateLoaded;
    if (forecasts && forecasts.length > 0) {
      state.kpiForecasts = forecasts;

      // Create forecast scope dicts with basic values to calculate forecast for calcualted kpis with formulas
      state.kpiForecastsBasicScopes = [];
      for (let forecast of forecasts) {
        var scope = {};
        for (let item of forecast.forecastValues) {
          scope["kpi_" + item.kpi] = item.value;
        }
        state.kpiForecastsBasicScopes.push(scope);
      }
    }
  },
  kpiForecastsError(state, error) {
    state.kpiForecastsLoadingState = stateError;
    state.kpiForecastsLoadingState.errorMessage = error.message;
  },
  kpiCategoriesLoaded(state, kpiCategories) {
    state.kpiCategories = kpiCategories;
  },
  kpiFinancialCategoriesLoaded(state, kpiFinancialCategories) {
    state.kpiFinancialCategories = kpiFinancialCategories;
  },
  customDashboardLoaded(state, customDashboard) {
    state.customDashboards = customDashboard;
  },
  backupCustomDashboard(state, backup) {
    state.kpiMeta = JSON.parse(JSON.stringify(backup));
  },
  setNewForecastValue(state, { kpiId, value }) {
    Vue.set(state.kpiNewForecastBasicScope, "kpi_" + kpiId, value);
  },
  updateCustomCategories(state, { id, name }) {
    for (let item in state.kpiMeta) {
      if (state.kpiMeta[item].category.id == id) {
        state.kpiMeta[item].category.name = name;
      }
    }
  },
  updateSelectedKpiForComparison(state, kpiId) {
    state.selectedKpiForComparison = kpiId;
  },
  updateSharedKpiIds(state, kpiIds) {
    state.sharedKpiIds = kpiIds;
  },
  updateKpiMetaData(state, { id, payload }) {
    state.kpiMeta = state.kpiMeta.map(kpi => (kpi.id == id ? { ...kpi, ...payload } : kpi));
  },
  updateShouldMergeDynamicKpiSection(state, shouldMerge) {
    state.shouldMergeDynamicKpiSection = shouldMerge;
  },
  updateKpiDimensions(state, dimensions) {
    state.kpiDimensions = dimensions;
  },
  setAvailableSourcesForCategory(state, { id, payload }) {
    state.forecastableSourcesForCategories[id] = payload;
  }
};

const actions = {
  // trigger HTTP request to update kpi monthly values
  updateKpiMonthlyValues(context, { kpisType, endDate }) {
    if (context.state.kpiUpdatedMonthlyValues.length) {
      context.commit("kpiHistoryUpdatedLoading");
      return updateKpiMonthlyValues({
        values: context.state.kpiUpdatedMonthlyValues
      })
        .then(
          () => {
            context.state.kpiUpdatedMonthlyValues.forEach(newKpiMonthlyValue => {
              let kpi;
              // in case we are on strategic, operational or custom
              // we find the kpi from those dashboards
              if (kpisType) {
                kpi = context.state[kpisType].kpis.find(kpi => kpi.id === newKpiMonthlyValue.kpi);

                // recalculate aggregate values
                if (kpisType === "financial") {
                  context.dispatch("loadKpiAggregates", {
                    type: "financial",
                    timeRange: 12,
                    endDate
                  });
                }
              } else if (context.state.kpis) {
                // else we find the kpis in the main list
                kpi = context.state.kpis.find(kpi => kpi.id === newKpiMonthlyValue.kpi);
              }
              // if kpi that was updated could be found in the strategic, operational, custom
              // or main kpis array we update it
              if (kpi) {
                kpi.value = newKpiMonthlyValue.value;
              }
              // in case the kpiHistory is loaded we find and update our kpi there as well
              if (context.state.kpiHistory && context.state.kpiHistory.length) {
                let kpiHistoryValue = context.state.kpiHistory[0].values.find(kpiHistoryValue => {
                  return kpiHistoryValue.date == newKpiMonthlyValue.date;
                });

                if (kpiHistoryValue) {
                  kpiHistoryValue.value = newKpiMonthlyValue.value;
                }
              }
              // if singleKpi is loaded we update kpi value as well
              if (context.state.singleKpi && context.state.singleKpi.kpi) {
                context.state.singleKpi.kpi.value = newKpiMonthlyValue.value;
              }
            });
            context.dispatch("loadKpiYears");
          },
          error => {
            context.commit("kpisHistoryUpdatesError", error);
          }
        )
        .finally(() => {
          // regardless if we had an error or not we move our loading state into loaded
          // and we reset the array of kpis values that are to be updated
          context.commit("kpiHistoryUpdatesLoaded");
          context.state.kpiUpdatedMonthlyValues = [];
        });
    }
  },
  editCustomPosition(context, selectedKpi) {
    editCustomPosition(selectedKpi.positionsToMove).then(
      () => {},
      error => {
        context.commit("kpiError", error);
      }
    );
  },

  async updateCustomCategories(context, payload) {
    try {
      await updateCustomCategories(payload);
    } catch (error) {
      context.commit("kpiError", error);
    }
  },

  setCurrency(context, payload) {
    context.commit("currencyLoading");
    setCurrency(payload.currency).then(
      () => {
        context.commit("currencyLoaded", payload.currency);
      },
      error => {
        context.commit("loadCurrencyError", error);
      }
    );
  },

  updateCurrencyCompany(context, selectedCurrency) {
    updateCurrencyCompany(selectedCurrency).then(
      response => {
        context.commit("updateCurrencyCompany", response.data);
      },
      error => {
        context.commit("updateCurrencyCompanyError", error);
      }
    );
  },

  editCurrency(context, exchangeRate) {
    editCurrency(exchangeRate).then(
      response => {
        context.commit("editCurrency", response.data);
      },
      error => {
        context.commit("editCurrencyError", error);
      }
    );
  },
  createCurrency(context, currencies) {
    createCurrency(currencies).then(
      response => {
        context.commit("createCurrency", response.data);
      },
      error => {
        context.commit("createCurrencyError", error);
      }
    );
  },
  loadKpiMetaData(context) {
    return new Promise((resolve, reject) => {
      context.commit("kpiMetaLoading");
      getKpiMeta().then(
        response => {
          context.commit("kpiMetaLoaded", response.data.kpis);
          resolve(response);
        },
        error => {
          context.commit("kpiMetaError", error);
          reject(error);
        }
      );
    });
  },
  loadKpiYears(context) {
    getKpiYears().then(response => {
      context.commit("kpiYearsLoaded", response.data);
    });
  },

  // Load a single KPI
  loadKpi(context, query) {
    context.commit("kpiLoading");
    getKpis(query).then(
      response => {
        context.commit("kpiLoaded", {
          ...query,
          kpi: response.data.kpis[0]
        });
      },
      error => {
        context.commit("kpiError", { ...query, error });
      }
    );
  },
  loadKpiList(context, query) {
    return new Promise((resolve, reject) => {
      context.commit("kpisLoading", { type: "kpiList" });
      getKpis(query).then(
        response => {
          context.commit("kpisLoaded", {
            type: "kpiList",
            kpis: response.data.kpis
          });
          resolve(response);
        },
        error => {
          context.commit("kpisError", { type: "kpiList", error });
          reject(error);
        }
      );
    });
  },
  // Load KPIs filtered by financial/strategic/operational/favorite/custom_{id}
  loadFilteredKpis(context, query) {
    context.commit("kpisLoading", query);
    getKpis(query).then(
      response => {
        context.commit("kpisLoaded", { ...query, kpis: response.data.kpis });
      },
      error => {
        if (axios.isCancel(error)) return;

        context.commit("kpisError", { ...query, error });
      }
    );
  },
  loadOperationalKpis(context, query) {
    const operationalQuery = {
      type: "operational",
      ...query
    };
    context.dispatch("loadFilteredKpis", operationalQuery);
  },
  loadStrategicKpis(context, query) {
    const strategicQuery = { type: "strategic", ...query };
    context.dispatch("loadFilteredKpis", strategicQuery);
  },
  loadFinancialKpis(context, query) {
    const financialQuery = { type: "financial", ...query };
    context.dispatch("loadFilteredKpis", financialQuery);
  },
  loadCustomKpis(context, query) {
    const customQuery = {
      type: "custom",
      customType: "custom_" + query.id,
      ...query
    };
    context.dispatch("loadFilteredKpis", customQuery);
  },
  loadFavoriteKpis(context, query) {
    const favoriteQuery = { type: "favorite", ...query };
    context.dispatch("loadFilteredKpis", favoriteQuery);
  },
  loadTreeViewKpis(context, query) {
    const treeViewQuery = { type: "treeview", ...query };
    context.dispatch("loadFilteredKpis", treeViewQuery);
  },

  loadHighLowPerformingKpis(context, query) {
    const highLowPerformingQuery = {
      type: "highLowPerforming",
      ...query
    };
    context.dispatch("loadFilteredKpis", highLowPerformingQuery);
  },

  loadKpiHistory(context, query) {
    if (!query) {
      query = context.state.kpiHistoryQuery;
    }
    context.commit("kpiHistoryLoading", query);
    getKpiHistory(query).then(
      response => {
        context.commit("kpiHistoryLoaded", {
          values: response.data.results,
          query
        });
      },
      error => {
        if (axios.isCancel(error)) return;

        context.commit("kpisHistoryError", error);
      }
    );
  },
  loadKpiPlanningHistory(context, query) {
    if (!query) {
      query = context.state.kpiPlanningHistoryQuery;
    }
    context.commit("kpiPlanningHistoryLoading", query);
    getKpiHistory(query).then(
      response => {
        context.commit("kpiPlanningHistoryLoaded", {
          values: response.data,
          query
        });
      },
      error => {
        context.commit("kpiPlanningHistoryError", error);
      }
    );
  },
  setKpiPlanningValues(context, payload) {
    return new Promise((resolve, reject) => {
      // TODO: reload planning history
      /**
       * This action can be used in combiantion with values saved in kpiUpdatedMonthlyValues.
       * An example is updating the values using the UpdateValuesModal and its child component InputCurrentMonth
       * In this case the payload argument is an empty object and its population with values from
       * context.state.kpiUpdateMonthlyValues d.
       */
      payload =
        Object.keys(payload).length === 0
          ? { values: context.state.kpiUpdatedMonthlyValues }
          : payload;
      putKpiPlanningValues(payload)
        .then(
          () => {
            context.dispatch("loadKpiPlanningHistory");
            resolve();
          },
          () => {
            reject();
          }
        )
        .finally(() => {
          context.state.kpiUpdatedMonthlyValues = [];
        });
    });
  },
  loadKpiHistoryPreview(context, payload) {
    const identifier = { kpi: payload.kpi, ...payload.query };
    context.commit("kpiHistoryPreviewLoading", identifier);
    getKpiHistory(payload.query).then(
      response => {
        context.commit("kpiHistoryPreviewLoaded", {
          ...identifier,
          data: response.data.results[0].values
        });
      },
      error => {
        context.commit("kpiHistoryPreviewError", { ...identifier, error });
      }
    );
  },
  loadKpiAggregates(context, newQuery) {
    const query = newQuery
      ? newQuery
      : {
          timeRange: context.state.timeRange,
          endDate: context.state.endDate
        };
    context.commit("kpiAggregatesLoading", query);
    getKpiAggregates(query).then(
      response => {
        context.commit("kpiAggregatesLoaded", response.data.results);
      },
      error => {
        if (axios.isCancel(error)) return;

        context.commit("kpiAggregatesError", error);
      }
    );
  },
  changeFavorite(context, payload) {
    context.commit("kpiFavoriteUpdating");
    const call = payload.favorite ? addKpiFavorite : deleteKpiFavorite;
    call(payload.kpiId).then(
      () => {
        context.commit("kpiFavoriteUpdated", payload);
      },
      error => {
        context.commit("kpiFavoriteError", error);
      }
    );
  },
  loadCurrency(context) {
    getCurrency().then(response => {
      context.commit(
        "currencyLoaded",
        response.data.selectedCurrency || {
          code: "EUR",
          exchangeRate: "1.0000"
        }
      );
    });
  },
  createFavoritesKpis(context, kpis) {
    return new Promise((resolve, reject) => {
      createFavoritesKpis(kpis).then(
        response => {
          if (kpis.length && kpis[0].favorite) {
            context.commit("favoriteKpisUpdate", kpis);
          }
          resolve(response);
        },
        error => {
          context.commit("kpiFavoriteError", error);
          reject(error);
        }
      );
    });
  },
  getAllCurrencies(context, payload) {
    context.commit("currenciesLoading");
    getAllCurrencies(payload).then(
      response => {
        context.commit("currenciesLoaded", response.data);
      },
      error => {
        context.commit("loadCurrenciesError", error);
      }
    );
  },
  updateCustomDashboard(context, payload) {
    return new Promise((resolve, reject) => {
      updateCustomDashboard(payload.id, payload).then(
        response => {
          context.dispatch("loadKpiMetaData");
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  addCustomKpi(context, payload) {
    return new Promise((resolve, reject) => {
      addCustomKpi(payload.id, payload.kpi).then(
        response => {
          context.dispatch("loadKpiMetaData").then(() => {
            resolve(response);
          });
        },
        error => {
          reject(error);
        }
      );
    });
  },
  addKpiToCustomDashboard(context, payload) {
    return new Promise((resolve, reject) => {
      addKpiToCustomDashboard(payload).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  removeKpiFromCustomDashboard(context, payload) {
    return new Promise((resolve, reject) => {
      removeKpiFromCustomDashboard(payload).then(
        response => {
          resolve(response);
          context.commit("removeKpiFromCustomDashboard", payload);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  addMultipleKpisToCustomDashboard(context, payload) {
    return new Promise((resolve, reject) => {
      addMultipleKpisToCustomDashboard(payload).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateCustomKpi(context, payload) {
    return new Promise((resolve, reject) => {
      updateCustomKpi(payload.id, payload.kpi).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateCustomDashboardById(context, payload) {
    return new Promise((resolve, reject) => {
      updateCustomDashboardById(payload).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateCustomDashboardPermissions(context, payload) {
    return new Promise(resolve => {
      updateCustomDashboardPermissions(payload).then(
        response => {
          context.dispatch("loadCustomDashboards");
          resolve(response);
        },
        error => {
          console.log(error);
        }
      );
    });
  },
  deleteCustomKpi(context, id) {
    return new Promise((resolve, reject) =>
      deleteCustomKpi(id).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      )
    );
  },
  saveCustomDashboardPositions(context, payload) {
    return new Promise((resolve, reject) => {
      saveCustomDashboardPositions(payload).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  addCustomKpiCategory(context, payload) {
    return new Promise((resolve, reject) => {
      addCustomKpiCategory(payload).then(
        response => {
          resolve(response);
          context.dispatch("loadKpiCategories");
        },
        error => {
          reject(error);
        }
      );
    });
  },
  addCustomDashboard(context, payload) {
    return new Promise((resolve, reject) => {
      createCustomDashboard(payload).then(
        response => {
          resolve(response);
          context.dispatch("loadCustomDashboards");
        },
        error => {
          reject(error);
        }
      );
    });
  },
  deleteCustomKpiCategory(context, id) {
    return new Promise((resolve, reject) =>
      deleteCustomKpiCategory(id).then(
        response => {
          resolve(response);
          context.dispatch("loadKpiCategories");
        },
        error => {
          reject(error);
        }
      )
    );
  },
  deleteCustomDashboardById(context, id) {
    return new Promise((resolve, reject) =>
      deleteCustomDashboardById(id).then(
        response => {
          resolve(response);
          context.commit("customDashboardReset");
        },
        error => {
          reject(error);
        }
      )
    );
  },
  loadKpiCategories(context) {
    getKpiCategories().then(
      response => {
        context.commit("kpiCategoriesLoaded", response.data);
      },
      error => {
        console.log(error);
      }
    );
  },
  async loadKpiFinancialCategories(context) {
    try {
      const response = await getFinancialKpiCategories();
      context.commit("kpiFinancialCategoriesLoaded", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  loadCustomDashboards(context) {
    context.commit("customDashboardLoading");
    getCustomDashboard().then(
      response => {
        context.commit("customDashboardLoaded", response.data);
      },
      error => {
        console.log(error);
      }
    );
  },
  loadLastKpiForecasts(context, n) {
    context.commit("kpiForecastsLoading");
    getLastForecasts(n).then(
      response => {
        context.commit("kpiForecastsLoaded", response.data);
      },
      error => {
        context.commit("kpiForecastsError", error);
      }
    );
  },
  saveNewForecast(context, payload) {
    return new Promise((resolve, reject) => {
      postNewForecast(payload).then(
        response => {
          resolve(response);
          context.dispatch("loadLastKpiForecasts", 2);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  async loadSharedKpiIds(context) {
    if (context.state.sharedKpiIds != null && context.state.sharedKpiIds.length > 0) return;
    let kpiIds = [];
    try {
      const response = await getSharedKpis();
      kpiIds = response.data.kpis;
      // if there is an error, submit an empty list instead
      // eslint-disable-next-line no-empty
    } catch (e) {}
    context.commit("updateSharedKpiIds", kpiIds);
    return kpiIds;
  },
  async updateKpiRecommendationsMetaData(context, { id, payload }) {
    try {
      await updateKpiRecommendationsMetaData(id, payload);
      context.commit("updateKpiMetaData", { id, payload });
    } catch (e) {
      console.error(e);
    }
  },
  updateShouldMergeDynamicKpiSection(context, shouldMerge) {
    context.commit("updateShouldMergeDynamicKpiSection", shouldMerge);
  },
  updateKpiMeta(context, data) {
    context.commit("updateKpiMetaData", data);
  },
  async loadKpiDimensions(context) {
    try {
      const response = await getKpiDimensions();
      context.commit("updateKpiDimensions", response.data);
    } catch (e) {
      console.error(e);
    }
  },
  async getForecastableSourcesForCategory(context, id) {
    try {
      const response = await getForecastableSources(id);
      context.commit("setAvailableSourcesForCategory", { id, payload: response.data });
      return response.data;
    } catch (e) {
      console.error(e);
    }
  },
  flushListviewFilters(context) {
    context.commit("setListviewFilters", null);
  },
  setListviewFilters(context, { id, payload }) {
    if (!context.listviewFilters) {
      const newFilter = {};
      newFilter[id] = payload;
      context.commit("setListviewFilters", newFilter);
      return;
    }
    if (!context.listviewFilters[id]) {
      const newFilter = { ...context.listviewFilters };
      newFilter[id] = payload;
      context.commit("setListviewFilters", newFilter);
    }
  }
};

function valueToBenchmark(item, benchmark, metaData, timeRange) {
  let value = item.value;
  if (timeRange > 1 && metaData.aggregationType === "cum") {
    value = value / timeRange;
  }
  if (metaData.higherIsBetter) {
    return value < benchmark.valueBottom25
      ? "bottom5"
      : value < benchmark.valueMedian50
      ? "bottom25"
      : value < benchmark.valueTop25
      ? "average50"
      : value < benchmark.top5
      ? "top25"
      : "top5";
  } else {
    return value > benchmark.valueBottom25
      ? "bottom5"
      : value > benchmark.valueMedian50
      ? "bottom25"
      : value > benchmark.valueTop25
      ? "average50"
      : value > benchmark.top5
      ? "top25"
      : "top5";
  }
}

function mergeMetaData(list, state, dateRange = 1) {
  // NOTE: the date range (in months) influences the calculation of the benchmark
  return (
    list &&
    list.flatMap(valuesItem => {
      const metaData = state.kpiMeta.find(item => item.id === valuesItem?.id);
      if (!metaData) {
        return [];
      }
      const benchmarks = metaData.benchmarks.map(benchmark => ({
        ...benchmark,
        benchmark: valueToBenchmark(valuesItem, benchmark, metaData, dateRange)
      }));
      return { ...metaData, ...valuesItem, benchmarks };
    })
  );
}

const getters = {
  getOperationalDashboardViewPosition: state => state.operationalDashboardViewPosition,
  kpiLoadingState: state => state.kpiState,
  kpis: state => state.kpis,
  kpiCategories: state => state.kpiCategories,
  kpiCategoriesMap: state =>
    state.kpiCategories.reduce((resultMap, category) => {
      resultMap[category.id] = category;
      return resultMap;
    }, {}),
  kpiFinancialCategories: state => state.kpiFinancialCategories,
  customDashboards: state => state.customDashboards,
  customDashboardLoadingState: state => state.customDashboardLoadingState,
  currency: state => state.currency,
  currencyState: state => state.currencyState,
  currencies: state => state.currencies,
  currenciesState: state => state.currenciesState,
  kpiMetaLoadingState: state => state.kpiMetaLoadingState,
  kpiMeta: state => state.kpiMeta,
  kpiMetaAlphabetical: state => [...state.kpiMeta].sort(kpiMetaSortFunction),
  kpiMetaMap: state =>
    state.kpiMeta.reduce((resultMap, kpi) => {
      resultMap[kpi.id] = kpi;
      return resultMap;
    }, {}),

  reportedYears: state => state.reportedYears,
  plannedYears: state => state.plannedYears,

  trendDuration: state => state.trendDuration,

  kpiHistoryState: state => state.kpiHistoryState,
  kpiHistory: state => state.kpiHistory,
  kpiHistoryUpdates: state => state.kpiHistoryUpdates,
  kpiHistoryUpdatesState: state => state.kpiHistoryUpdatesState,
  kpiUpdatedMonthlyValues: state => state.kpiUpdatedMonthlyValues,
  kpiPlanningHistoryState: state => state.kpiPlanningHistoryState,
  kpiPlanningHistory: state => state.kpiPlanningHistory,
  kpiPlanningHistoryYear: state => state.kpiPlanningHistoryYear,
  kpiHistoryAggregates: state => state.kpiHistoryAggregates,
  kpiAggregateState: state => state.kpiAggregateState,
  kpiAggregates: state => state.kpiAggregates,
  kpiHistoryPreviews: state => state.kpiHistoryPreviews,

  favoriteKpis: state => mergeMetaData(state.favorite.kpis, state),
  favoriteKpiState: state => state.favorite.state,
  favoriteKpiParams: state => state.favorite,

  operationalKpis: (state, getters, rootState) => {
    return mergeMetaData(state.operational.kpis, state, rootState.dateModule.reportingDateRange);
  },
  operationalKpiState: state => state.operational.state,
  operationalKpiParams: state => state.operational,

  strategicKpis: (state, getters, rootState) => {
    return mergeMetaData(state.strategic.kpis, state, rootState.dateModule.reportingDateRange);
  },
  strategicKpiState: state => state.strategic.state,
  strategicKpiParams: state => state.strategic,

  financialKpis: state => mergeMetaData(state.financial.kpis, state),
  financialKpiState: state => state.financial.state,
  financialKpiParams: state => state.financial,

  customKpis: (state, getters, rootState) => {
    return mergeMetaData(state.custom.kpis, state, rootState.dateModule.reportingDateRange);
  },
  customKpiState: state => state.custom.state,
  customKpiParams: state => state.custom,

  treeViewKpis: state => mergeMetaData(state.treeview.kpis, state),
  treeViewKpiState: state => state.treeview.state,
  treeViewKpiParams: state => state.treeview,

  highPerformingKpis: state => mergeMetaData(state.highLowPerforming.kpis.high, state),
  lowPerformingKpis: state => mergeMetaData(state.highLowPerforming.kpis.low, state),
  highLowPerformingKpiState: state => state.highLowPerforming.state,

  kpiList: state => mergeMetaData(state.kpiList.kpis, state),
  kpiListLoadingState: state => state.kpiList.state,

  singleKpi: state => {
    const merged = mergeMetaData(state.singleKpi.kpi ? [state.singleKpi.kpi] : [], state);
    if (merged.length > 0) {
      return merged[0];
    }
    return undefined;
  },
  singleKpi2: state => {
    const merged = mergeMetaData(state.singleKpi.kpi2 ? [state.singleKpi.kpi2] : [], state);
    if (merged.length > 0) {
      return merged[0];
    }
    return undefined;
  },
  singleKpiState: state => state.singleKpi.state,

  filterSettings: state => state.filterSettings,

  kpiForecasts: state => state.kpiForecasts,
  kpiForecastsBasicScopes: state => state.kpiForecastsBasicScopes,
  kpiNewForecastBasicScope: state => state.kpiNewForecastBasicScope,
  kpiForecastsLoadingState: state => state.kpiForecastsLoadingState,

  selectedKpiForComparison: state => state.selectedKpiForComparison,
  sharedKpiIds: state => state.sharedKpiIds,
  shouldMergeDynamicKpiSection: state => state.shouldMergeDynamicKpiSection,

  kpiDimensions: state => state.kpiDimensions,
  forecastableSourcesForCategories: state => state.forecastableSourcesForCategories,
  listviewFilters: state => state.listviewFilters
};

export const kpisModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};

function kpiMetaSortFunction(a, b) {
  const replaceNonwordAndDigitsWithZ = s => s.replace(/^[\W\d]+/, "z");

  return replaceNonwordAndDigitsWithZ(a.name).localeCompare(replaceNonwordAndDigitsWithZ(b.name));
}
