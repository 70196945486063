import { stateInitial, stateLoading, stateLoaded, stateError } from "./util";
import { list, del } from "@/services/oauthService.js";

const initialState = {
  registeredInstances: [],
  registeredInstancesState: stateInitial
};

const actions = {
  async getRegisteredInstances(context) {
    context.commit("updateRegisteredInstancesState", stateLoading);
    try {
      const response = await list();
      context.commit("updateRegisteredInstances", response.data);
      context.commit("updateRegisteredInstancesState", stateLoaded);
    } catch (e) {
      console.error(e);
      context.commit("updateRegisteredInstancesState", stateError);
    }
  },
  async deleteRegisteredInstance(context, id) {
    context.commit("updateRegisteredInstancesState", stateLoading);
    try {
      await del(id);
      context.dispatch("getRegisteredInstances");
    } catch (e) {
      console.error(e);
      context.commit("updateRegisteredInstancesState", stateError);
    }
  }
};

const mutations = {
  updateRegisteredInstances(state, registeredInstances) {
    state.registeredInstances = registeredInstances;
  },
  updateRegisteredInstancesState(state, newState) {
    state.registeredInstancesState = newState;
  }
};

const getters = {
  registeredInstances: state => state.registeredInstances,
  registeredInstancesState: state => state.registeredInstancesState
};

export const oauthModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
