import axios from "axios";
import { warehouseRoot, apiRoot, goApiRoot } from "@/preferences";
import { QS } from "@/services/util";
import { keyMapping, createAbortController } from "vw/signals";

export function getKpiMeta() {
  return axios.get(`${apiRoot}/kpis/meta/`);
}

export function getKpis(query) {
  let queryParams = {
    ...query
  };
  if (query.customType || query.type) {
    queryParams.type = query.customType || query.type;
  }

  if ("includePlanning" in queryParams) {
    queryParams.includePlanning = queryParams.includePlanning ? "true" : "false";
  }

  if ("includeHidden" in queryParams) {
    queryParams.includeHidden = queryParams.includeHidden ? "true" : "false";
  }

  if ("idList" in queryParams) {
    if (typeof queryParams.idList === "string") {
      queryParams.idList = queryParams.idList.split(",");
    }
    queryParams.idList = queryParams.idList.map(i => +i).filter(i => i !== 0);
  }

  if ("id" in queryParams) {
    queryParams.id = +queryParams.id;
  }

  return axios.post(`${goApiRoot}/kpi/values`, queryParams, {
    signal: createAbortController(keyMapping.kpisValues)
  });
}

export function getKpiAggregates(query) {
  return axios.get(`${goApiRoot}/kpi/aggregates?${new URLSearchParams(query)}`, {
    signal: createAbortController(keyMapping.kpiAggregates)
  });
}

export function getKpiYears() {
  return axios.get(`${apiRoot}/kpis/values/years/`);
}

export function getKpiHistory(query) {
  return axios.get(`${goApiRoot}/kpi/history/?${new URLSearchParams(query)}`, {
    signal: createAbortController(keyMapping.kpiValuesHistory)
  });
}

// DEPRECATED
export function getKpiPlanningHistory(query) {
  return axios.get(`${apiRoot}/kpi/planning/history/?${new URLSearchParams(query)}`);
}

export function createFavoritesKpis(kpis) {
  return axios.post(`${apiRoot}/kpi/favorites/create/`, kpis);
}

export function addKpiFavorite(id) {
  return axios.post(`${apiRoot}/kpi/${id}/favorite/`);
}
export function deleteKpiFavorite(id) {
  return axios.delete(`${apiRoot}/kpi/${id}/favorite/`);
}

// Update currency in Company model in customer Backend
export function setCurrency(currency) {
  return axios.patch(`${apiRoot}/kpi/currency/update/`, { currency });
}

export function createCurrency(currency) {
  return axios.post(`${apiRoot}/kpi/currencies/create/`, currency);
}

export function getCurrency() {
  return axios.get(`${apiRoot}/kpi/currencies/company/`);
}

export function getAllCurrencies() {
  return axios.get(`${apiRoot}/kpi/currencies/`);
}

export function updateCurrencyCompany(selectedCurrency) {
  return axios.patch(`${apiRoot}/kpi/currencies/company/update/`, selectedCurrency);
}

// get a comment
export function getComment(id, query) {
  return axios.get(`${apiRoot}/kpi/comment/get/${id}/?${new URLSearchParams(query)}`);
}

export function getComments(query) {
  return axios.get(`${apiRoot}/kpi/comment/list/?${new URLSearchParams(query)}`);
}

export function getSubComments(query) {
  return axios.get(`${apiRoot}/kpi/subcomment/list/?${new URLSearchParams(query)}`);
}

// Create a comment
export function addComment(payload) {
  return axios.post(`${apiRoot}/kpi/comment/create/`, payload);
}

// Edit a comment
export function editComment(comment) {
  return axios.patch(`${apiRoot}/kpi/comment/edit/${comment.id}/`, comment);
}

// Delete a comment
export function deleteComment(id) {
  return axios.delete(`${apiRoot}/kpi/comment/edit/${id}`);
}

// Get custom Listview
export function getCustomListView(id) {
  return axios.get(`${apiRoot}/kpi/${id}/customlistview`);
}

// Edit custom Listview
export function editCustomListView(id, queryParams, listview) {
  return axios.patch(`${apiRoot}/kpi/${id}/customlistview/?${QS(queryParams)}`, listview);
}

export function updateKpiMonthlyValues(payload) {
  return axios.put(`${apiRoot}/kpi/values/`, payload);
}

export function editCurrency(exchangeRate) {
  return axios.patch(`${apiRoot}/kpi/currencies/edit/${exchangeRate.code}/`, exchangeRate);
}

export function editCustomPosition(kpiCustomPositions) {
  return axios.patch(`${apiRoot}/kpi/custom/positions/`, kpiCustomPositions);
}

export function patchKpiMeta(id, payload) {
  return axios.patch(`${apiRoot}/kpi/${id}/management/`, payload);
}

export function putKpiPlanningValues(payload) {
  return axios.put(`${apiRoot}/kpi/planningValues/`, payload);
}

// Add or remove KPI from custom dashboard
export function updateCustomDashboard(id, payload) {
  return axios.patch(`${apiRoot}/kpi/update/customdashboard/${id}/`, payload);
}

export function updateCustomDashboardPermissions(payload) {
  return axios.post(`${apiRoot}/kpi/custom/dashboard/permission/`, payload);
}

//Add custom kpi in the customer Backend
export function addCustomKpi(id, payload) {
  return axios.post(`${apiRoot}/kpi/add/custom/`, payload);
}

//Add a KPI to a custom dashboard
export function addKpiToCustomDashboard(payload) {
  return axios.post(`${apiRoot}/kpi/custom/dashboard/add/`, payload);
}

export function addMultipleKpisToCustomDashboard(payload) {
  return axios.post(`${apiRoot}/kpi/custom/dashboard/add/list/`, payload);
}

//update custom kpi in the customer Backend
export function updateCustomKpi(id, payload) {
  return axios.patch(`${apiRoot}/kpi/edit/custom/${id}/`, payload);
}
//delete custom kpi in the customer Backend
export function deleteCustomKpi(id) {
  return axios.delete(`${apiRoot}/kpi/edit/custom/${id}/`);
}
//add custom Kpi category in the customer backend
export function addCustomKpiCategory(payload) {
  return axios.post(`${apiRoot}/kpi/category/add/custom/`, payload);
}

export function saveCustomDashboardPositions(payload) {
  return axios.post(`${apiRoot}/kpi/custom/dashboard/positions`, payload);
}

//Create a new custom dashboard
export function createCustomDashboard(payload) {
  return axios.post(`${apiRoot}/kpi/custom/dashboard/create/`, payload);
}

//Get a list of all custom dashboards.
export function getCustomDashboard() {
  return axios.get(`${apiRoot}/kpi/custom/dashboard/`);
}

//Update a custom dashboard by id
export function updateCustomDashboardById(payload) {
  return axios.patch(`${apiRoot}/kpi/custom/dashboard/${payload.id}/`, payload);
}

export function updateCustomCategories(payload) {
  return axios.patch(`${apiRoot}/kpi/categories/user/`, payload);
}

//Delete a custom dashboard by id
export function deleteCustomDashboardById(id) {
  return axios.delete(`${apiRoot}/kpi/custom/dashboard/${id}/`);
}

export function removeKpiFromCustomDashboard(payload) {
  return axios.delete(`${apiRoot}/kpi/custom/dashboard/${payload.dashboard}/remove/${payload.kpi}`);
}

// Custom dynamic dashboards
export function getDynamicCustomDashboard(id) {
  return axios.get(`${apiRoot}/kpi/dynamic/dashboard/${id}/`);
}

export function updateDynamicCustomDashboard(id, payload) {
  return axios.patch(`${apiRoot}/kpi/dynamic/dashboard/${id}/update/`, payload);
}

export function getCustomDashboardDeepDrills(customDashboardId) {
  return axios.get(`${apiRoot}/kpi/dynamic/dashboard/${customDashboardId}/deepdrills/`);
}

export function createCustomDashboardDeepDrills(customDashboardId, payload) {
  return axios.post(
    `${apiRoot}/kpi/dynamic/dashboard/${customDashboardId}/deepdrills/create/`,
    payload
  );
}

//delete custom kpi category from the customer Backend
export function deleteCustomKpiCategory(id) {
  return axios.delete(`${apiRoot}/kpi/category/delete/custom/${id}/`);
}
//List kpi categories ordered by position from customer backend
export function getKpiCategories() {
  return axios.get(`${apiRoot}/kpi/categories/list/`);
}

export function getFinancialKpiCategories() {
  return axios.get(`${apiRoot}/kpi/categories/financial/list/`);
}

export function getLastForecasts(n) {
  return axios.get(`${apiRoot}/kpis/forecast/?n=${n}`);
}

export function postNewForecast(forecast) {
  return axios.post(`${apiRoot}/kpis/forecast/`, forecast);
}

export function getRefreshedCalculations() {
  return axios.get(`${apiRoot}/kpis/refresh-calculations/`);
}

export function createFavoriteDeepDrills(deepdrills) {
  return axios.post(`${apiRoot}/kpi/deepdrills/favorites/create/`, deepdrills);
}

export function getDeepDrills() {
  return axios.get(`${apiRoot}/kpi/deepdrills/list/`);
}

export function updateKpiRecommendationsMetaData(kpiId, payload) {
  return axios.patch(`${apiRoot}/kpis/meta/recommendations/${kpiId}/`, payload);
}

export function kpiRecommendations(payload) {
  return axios.post(`${warehouseRoot}/recommendation/kpi`, payload);
}

// alerts
export function getAlerts(kpiId) {
  return axios.get(`${apiRoot}/kpi/${kpiId}/alerts/`);
}

export function postAlert(kpiId, payload) {
  return axios.post(`${apiRoot}/kpi/${kpiId}/alert/`, payload);
}

export function deleteAlert(alertId) {
  return axios.delete(`${apiRoot}/kpi/alert/${alertId}/`);
}

export function updateAlert(alertId, payload) {
  return axios.patch(`${apiRoot}/kpi/alert/${alertId}/`, payload);
}

export function setDisplayedTimeUnit(id, displayedTimeUnit) {
  return axios.patch(`${apiRoot}/kpi/meta/updateDisplayedTimeUnit/${id}/`, { displayedTimeUnit });
}

export function getListViewChartSchemas(kpiId) {
  return axios.get(`${apiRoot}/kpi/${kpiId}/charts/`);
}

export function getAllListViewChartSchemas() {
  return axios.get(`${apiRoot}/kpi/charts/`);
}

export function getListViewChartSchema(kpiId, chartId) {
  return axios.get(`${apiRoot}/kpi/${kpiId}/chart/${chartId}/`);
}

export function uploadListViewChartSchema(kpiId, payload) {
  return axios.post(`${apiRoot}/kpi/${kpiId}/chart/`, payload);
}

export function deleteListViewChartSchema(kpiId, chartId) {
  return axios.delete(`${apiRoot}/kpi/${kpiId}/chart/${chartId}/`);
}

export function updateSingleListViewChartSchema(kpiId, chartId, payload) {
  return axios.patch(`${apiRoot}/kpi/${kpiId}/chart/${chartId}/`, payload);
}

export function setBenchmarkLevel(id, benchmarkLevel) {
  return axios.patch(`${apiRoot}/kpis/meta/updateBenchmarkLevel/${id}/`, { benchmarkLevel });
}

export function previewMultiDimensionalKpiUpload(file) {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(`${apiRoot}/kpi/multidimension/preview/`, formData);
}

export function valuesForColumnMultiDimensionalKpiUpload(file, column) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("column", column);

  return axios.post(`${apiRoot}/kpi/multidimension/values/`, formData);
}

export function multiDimensionalKpiUpload(file, { columns, dimensions, ids, isPlanning }) {
  const json = JSON.stringify({ columns, dimensions, ids, isPlanning });
  const blob = new Blob([json], {
    type: "application/json"
  });

  const formData = new FormData();
  formData.append("file", file);
  formData.append("data", blob);

  return axios.post(`${apiRoot}/kpi/multidimension/upload/`, formData);
}

export function getKpiDimensions() {
  return axios.get(`${apiRoot}/kpi/dimensions/`);
}

export function getKpiDimensionsForKpis(ids) {
  return axios.post(
    `${apiRoot}/kpi/dimensions/kpis/`,
    { ids },
    {
      signal: createAbortController(keyMapping.kpiDimensionsForKpis)
    }
  );
}

export function getKpiMultidimensionUploadSchema() {
  return axios.get(`${apiRoot}/kpi/multidimension/upload/schema/`);
}

export function updateKpiMeta(id, payload) {
  return axios.patch(`${apiRoot}/kpis/meta/${id}/`, payload);
}
